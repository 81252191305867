<template>
  <div class="timeActivities">
    <van-list v-model="loading" :finished="finished" :finished-text="$t.noMoreData" @load="onLoad">
      <div class="bannerBox">
        <lists class="listsBox" :productList="productList"></lists>
      </div>
    </van-list>
  </div>
</template>
<script>
import Lists from "@main/common/Lists";

import Vue from "vue";
import { Sticky } from "vant";
import { List } from "vant";
Vue.use(List).use(Sticky);
export default {
  name: "TimeActivities",
  components: {
    Lists
  },
  data() {
    return {
      productList: [],
      sreachValues: "", //搜索关键词
      pageIndex: 1,
      order: "", //排序
      //isNoData: false,
      //isfirst: true,
      loading: true,
      finished: false,
      pageSize: 6
    };
  },
  computed: {
    queryParams() {
      return this.$route.query;
    }
  },
  mounted() {
    this.loadIndex();
  },
  methods: {
    // 获取首页数据
    loadIndex() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        SreachValues: this.queryParams.id,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize
      };
      this.$api.index
        .loadBannerProductByPage(param)
        .then(res => {
          const data = res.data;
          this.productList = data.ProductList;
          this.loading = false;
          if (res.DataCounts < 6) {
            this.finished = true;
          }
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.isfirst = false;
          console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    //加载更多
    onLoad() {
      // this.loading = true;
      // this.finished = false;
      console.log("执行加载更多");
      let param = {
        SreachValues: this.queryParams.id,
        PageIndex: this.pageIndex + 1,
        PageSize: this.pageSize
      };
      this.$api.index
        .loadBannerProductByPage(param)
        .then(res => {
          //console.log(res.data);
          const data = res.data.ProductList;
          console.log(data);
          if (!data || data.length === 0) {
            this.loading = false;
            this.finished = true;
            //this.isNoData = true;
          } else {
            this.loading = false;
            this.productList = this.productList.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch(error => {
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.timeActivities {
  position: relative;
  .bannerBox {
    position: relative;
    .bannerImg {
      width: 100%;
    }
    .bannerInfoBox {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      text-align: center;
    }
    .bannerInfo {
      position: relative;
      width: 100%;
      margin: 0 auto;
    }
  }
}
</style>
